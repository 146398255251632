import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const About = () => {
  return (
    <Container className="About-Container" sx={{backgroundColor: '#282828', py: 4 }}>
      <Grid container spacing={3}>
        {/* Row 1: Image followed by Text */}
        <Grid item xs={6}>
          <img src="/mat2.jpg" alt="Description 1" className="About-Image" />
        </Grid>
        <Grid item xs={6} justifyContent={'center'}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
            width={'100%'}
            >
            <Stack direction="column">
                <Typography variant="h4">Mathew Dostal</Typography>
                <Typography variant="body1">
                    Dostal has worked many hats in the software 
                    industry and in many business verticals.&nbsp; 
                    Having spent a large focus of time in the event 
                    space for both side projects and festival companies, 
                    he has been thinking about making Firefly Events a 
                    reality for a number of years now.
                </Typography>
            </Stack>
          </Box>
        </Grid>

        {/* Row 2: Text followed by Image */}
        <Grid item xs={6}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
            width={'100%'}
            >
            <Stack direction="column">
                <Typography variant="h4">Don Matthews</Typography>
                <Typography variant="body1">
                Don has worked as a consultant servicing a variety of clients types and project types. He prefers native mobile development and has help clients realize their mobile application goals. Now Don is applying those skills as Mobile development lead and Mobile application architect.                </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <img src="/don.jpg" alt="Description 2" className="About-Image" />
        </Grid>

        {/* Row 3: Image followed by Text */}
        <Grid item xs={6}>
          <img src="/nikita2.jpg" alt="Description 3"  className="About-Image"/>
        </Grid>
        <Grid item xs={6}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
            width={'100%'}
            >
            <Stack direction="column">
                <Typography variant="h4">Nikita Kramar</Typography>
                <Typography variant="body1">
                Nikita Kramar is a talented software engineer with a passion for simplifying event and venue searching. Hailing from Russia, Nikita made a life-changing journey to the United States in 2015, bringing with him a unique blend of cultural experiences and technical expertise.
                </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;