


import React from 'react';
import Container from '@mui/material/Container';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore"; 
import { useState } from 'react';

const Home = () => {

    // TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDW8FZ18E_iisQyrMS4WX6ewtZmrkN4xP4",
    authDomain: "firefly-events-inc.firebaseapp.com",
    projectId: "firefly-events-inc",
    storageBucket: "firefly-events-inc.appspot.com",
    messagingSenderId: "26289873408",
    appId: "1:26289873408:web:796f39b0e074100ac76ee5",
    measurementId: "G-NR4591JNL0"
  };
  
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const db = getFirestore(app);
    const emails = collection(db, 'contact-us');
  
  
    const handleSignUp = () => {
  
        const docRef = addDoc(emails, {
          email: email
        }).then(() => {
          setMessage("Thank you for signing up!");
        })
        .catch((error) => 
        {
          setMessage("Sign-up failed. " + error.message);
        });
    };

  return (
    <Container>
<div className="overlay">
  <div className="App-text">
    <h1 >Firefly Events</h1>
    <h3>From event discovery to venue booking - we cover it all.</h3>

  </div>
  <div className="Sign-Up">
    <span className='row'>
      <p className="sign-desc">Sign up to the mailing list in order to get more info and hear about our releases!</p>
    </span>
      <input
      type="email"
      className='email-input'
      placeholder="Your email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
    <button className='subscribe-button' onClick={handleSignUp}>Sign Up</button>
    <p>{message}</p>
  </div>
</div>

</Container>
  );
};

export default Home;