import React from 'react';
import Container from '@mui/material/Container';

const Privacy = () => {
  return (
    <Container>
        {/* <div className='overlay'> */}
      {/* <ProductCarousel className="Carousel" products={products} /> */}
        <h3> Privacy Policy for Shindig</h3>
        <h4>Effective Date: 01/01/2024</h4>
        <div>
        Welcome to Shindig, powered by Firefly Events. Your privacy is of paramount importance to us. This Privacy Policy outlines the types of information we collect from our users, how we use it, and the measures we take to protect it.

1. Information Collection

Personal Information: We collect personal information you provide when you register, including but not limited to your name, email address, and location.
Usage Data: We gather data about your interactions with our app, such as event searches, bookings, and preferences.
2. Use of Information

To provide and improve our services, including facilitating event discovery and venue bookings.
To communicate with you about your account, updates, or promotional offers.
To analyze app usage for optimizing our service offerings.
3. Information Sharing

We do not sell or rent your personal information to third parties.
Information may be shared with service providers who assist us in operating the app, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
We may also release information when its release is appropriate to comply with the law, enforce our app policies, or protect ours or others' rights, property, or safety.
4. Data Security

We implement a variety of security measures to maintain the safety of your personal information.
Despite our efforts, no method of transmission over the Internet, or method of electronic storage, is 100% secure.
5. Cookies and Tracking Technologies

We may use cookies and similar tracking technologies to track activity on our service and hold certain information.
6. Third-Party Links

Our app may contain links to third-party sites. We are not responsible for the privacy practices of these external sites.
7. Children’s Privacy

Our app is not intended for individuals under the age of 18. We do not knowingly collect personally identifiable information from children under 18.
8. Changes to This Privacy Policy

We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
9. Contact Us

If you have any questions about this Privacy Policy, please contact us at [Shindig Support Email].
By using Shindig, you consent to this privacy policy.
        </div>
    </Container>

  );
};

export default Privacy