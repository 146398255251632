import React, { Children } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#282828' }} >
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
        Firefly Events
        </Typography>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}
        spacing={2}>
        <Button 
          className={isActive('/')? 'Header-Button-Active':'Header-Button'}
          color="inherit" 
          component={Link} 
          to="/"
          >Home</Button>
        <Button
          className={isActive('/products')? 'Header-Button-Active':'Header-Button'}
          color="inherit" 
          component={Link} 
          to="/products"
          >Products</Button>
        <Button 
          className={isActive('/about')? 'Header-Button-Active':'Header-Button'}
          color="inherit" 
          component={Link} 
          to="/about"
          >About Us</Button>
        {/* <Button 
          className={isActive('/contact')? 'Header-Button-Active':'Header-Button'}
          color="inherit" 
          component={Link} 
          to="/contact"
          >Contact Us</Button> */}
          </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;