import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import ProductCarousel from '../components/productCarousel';


const products = [
  {
    title: 'Shindig',
    image: '/map.png',
    description: 'Find Events Near You Now!'
  },
  {
    title: 'Venues',
    image: '/coming-soon.jpg',
    description: 'We\'ll discuss this more as time comes!'
  },
  {
    title: 'Dashboard',
    image: '/coming-soon.jpg',
    description: 'More to be released on this as we go'
  },
];

const Products = () => {
  return (
    <Container>
        {/* <div className='overlay'> */}
      {/* <ProductCarousel className="Carousel" products={products} /> */}
        <h3> Shindig</h3>
        <p>Early Phase Testing Sign Up: <a className="link" href="https://forms.gle/VkLoKDDT8BXp52WY9">Sign Up Sheet</a></p> 
        <div>
            <a href="https://app.uizard.io/p/96052ef5"> Demo Link</a>
        </div>
        {/* <Stack direction="row"> */}
            <img alt="Shindig App screenshot" src='./map.png'></img>
            {/* <Stack direction="column">
                <p>Shindig lets you find events near you now!</p>
                <p>Travel like a local!</p>
                <p>What's happening right now near you?!</p>
            </Stack>
        </Stack> */}
      {/* </div> */}
    </Container>

  );
};

export default Products