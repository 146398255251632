// import logo from './logo.svg';

import './App.css';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About-Us';
import Products from './pages/Products';
import Privacy from './pages/Privacy';


function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Helmet>
            <title>Firefly Events - Coming Soon</title>
            <meta name="description" content="Discover and host events effortlessly with Firefly Events. Coming soon." />
          </Helmet>
        <Header />
        </header>
        <div className="App-Content">
        <Routes>
            <Route path="/" exact  element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/products" element={<Products/>} />
            <Route path="/privacy" element={<Privacy/>}/>
            {/* <Route path="/contact" element={<Contact/>} /> */}
        </Routes>
        </div>
        {/* <Footer></Footer> */}
      </div>
    </Router>
  );
}

export default App;
